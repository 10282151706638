import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import { fontSizeLead } from 'constants/typography'
import colors from 'constants/colors'

export const Container = styled.div`
  ${MEDIA.TABLET`
    display: block;
  `};
`

export const Title = styled.h1``

export const SubTitle = styled.p`
  font-size: ${fontSizeLead};
  color: ${colors.gray};
`
