import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Layout from 'components/layout'
import WorksListFull from 'components/worksListFull'
import PageHeader from 'components/pageHeader'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const WorksListWrapper = styled.div`
  max-width: 1200px;
  padding: 0 1rem;
  margin: 4rem auto 4rem;
`

const WorkSingle = ({ data: { mdx } }) => (
  <Layout>
    <PageHeader
      title={mdx.frontmatter.title}
      subTitle={mdx.frontmatter.description}
    >
      <a href={mdx.frontmatter.url} title="Project website">
        {mdx.frontmatter.url}
      </a>
    </PageHeader>
    <MDXProvider>
      <MDXRenderer>{mdx.body}</MDXRenderer>
    </MDXProvider>
    <WorksListWrapper>
      <WorksListFull />
    </WorksListWrapper>
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        description
        url
      }
    }
  }
`

WorkSingle.propTypes = {
  data: PropTypes.any,
}

export default WorkSingle
