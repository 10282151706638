import React from 'react'
import PropTypes from 'prop-types'
import Box from 'components/box'
import { Container, Title, SubTitle } from './pageHeader.css'

const PageHeader = ({ title, subTitle }) => (
  <Container>
    <Box>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </Box>
  </Container>
)

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
}

export default PageHeader
